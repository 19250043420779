export default {
   altTeal: '#81E1E1',
   altLavender: '#AE93F9',
   altPink: '#F77F9C',
   altTangerine: '#FC8A66',
   altLime: '#D8F080',
   black: '#11161A',
   blue: '#1975F1',
   blueDark: ['#186AD7', '#155BBA', '#0F4C9F', '#104183'],
   blueLight: ['#4291FC', '#74ADFA', '#B9D6FF', '#ECF4FF'],
   gray: ['#F6F8FA', '#E3E8ED', '#D2DADF', '#AFBBC4', '#828D96', '#525D66', '#39444D', '#242C33'],
   green: '#50CD63',
   greenDark: ['#52AF60', '#4F975A', '#437C4C', '#38623F'],
   greenLight: ['#79DC87', '#9FE8AB', '#CFF6D5', '#E9FEEC'],
   red: '#F34E46',
   redDark: ['#D13932', '#B8322C', '#9E2B26', '#852420'],
   redLight: ['#FF6E68', '#FD9A96', '#FFC3C0', '#FFEBEA'],
   white: '#FFFFFF',
   yellow: '#F9BA2A',
   yellowDark: ['#DEA017', '#C29124', '#A17E2F', '#886B2B'],
   yellowLight: ['#F9C34A', '#F7D17C', '#FFE7B2', '#FFF8E7'],
};
