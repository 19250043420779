import * as React from 'react';
import styled from 'styled-components';
import { color, fontSize, borderRadius, space, fontWeight } from '@dozuki/web-js/primitives';
import { stylesByButtonType } from './button-colors';

export type ButtonSize = 'default' | 'small' | 'large';

type StylesBySize<T> = {
   [key in ButtonSize]: T;
};

export type ButtonType =
   | 'default'
   | 'primary'
   | 'secondary'
   | 'dark'
   | 'light'
   // Legacy type (leftover for compatibility)
   | 'dark-legacy';

export type ButtonColor = 'default' | 'green' | 'red' | 'yellow' | 'blue' | 'black';

const BUTTON_SIZES: StylesBySize<{ height: string; minWidth: string }> = {
   default: {
      height: '36px',
      minWidth: '36px',
   },
   small: {
      height: '30px',
      minWidth: '30px',
   },
   large: {
      height: '48px',
      minWidth: '48px',
   },
};

const BUTTON_TYPOGRAPHY: StylesBySize<{ fontSize: string; fontWeight: number }> = {
   default: {
      fontSize: fontSize.md,
      fontWeight: fontWeight.bold,
   },
   small: {
      fontSize: fontSize.md,
      fontWeight: fontWeight.normal,
   },
   large: {
      fontSize: fontSize.lg,
      fontWeight: fontWeight.bold,
   },
};

const BUTTON_PADDING: StylesBySize<string> = {
   default: `0 ${space[4]}`,
   small: `0 ${space[3]}`,
   large: `0 ${space[5]}`,
};

const ButtonInput = styled.button<ButtonProps>`
   cursor: pointer;
   font-family: inherit;
   font-size: ${props => BUTTON_TYPOGRAPHY[props.size].fontSize};
   font-weight: ${props => BUTTON_TYPOGRAPHY[props.size].fontWeight};
   line-height: 1;
   height: ${props => BUTTON_SIZES[props.size].height};
   min-width: ${props => BUTTON_SIZES[props.size].minWidth};
   padding: ${props => BUTTON_PADDING[props.size]};
   color: ${props => stylesByButtonType[props.buttonType][props.buttonColor].default.fontColor};
   background-color: ${props =>
      stylesByButtonType[props.buttonType][props.buttonColor].default.backgroundColor};
   border: ${props => stylesByButtonType[props.buttonType][props.buttonColor].default.border};
   border-radius: ${borderRadius.md};

   svg {
      vertical-align: text-bottom;
   }

   > :not(:first-child) {
      margin-left: ${space[2]};
   }

   &:hover {
      background-color: ${props =>
         stylesByButtonType[props.buttonType][props.buttonColor].hover.backgroundColor};
      border: ${props => stylesByButtonType[props.buttonType][props.buttonColor].hover.border};
      color: ${props => stylesByButtonType[props.buttonType][props.buttonColor].hover.fontColor};
   }

   &:focus {
      outline: none;
      background-color: ${props =>
         stylesByButtonType[props.buttonType][props.buttonColor].focus.backgroundColor};
      border: ${props => stylesByButtonType[props.buttonType][props.buttonColor].focus.border};
      color: ${props => stylesByButtonType[props.buttonType][props.buttonColor].focus.fontColor};
   }

   &:disabled {
      cursor: not-allowed;
      color: ${color.gray[500]};
      background-color: rgba(0, 35, 70, 0.1);
      border: none;
   }

   @media (max-width: ${props => props.mediaBreakpoint}) {
      height: ${props => BUTTON_SIZES[props.size !== 'default' ? props.size : 'large'].height};
   }
`;

function adjustAnchorButtonHeight(props: ButtonProps) {
   const border = stylesByButtonType[props.buttonType][props.buttonColor].default.border;
   const height = BUTTON_SIZES[props.size].height;
   if (border !== 'none') {
      return `calc(${height} - 2px)`;
   }
   return height;
}

const ButtonAnchor = styled.a<ButtonProps>`
   cursor: pointer;
   font-family: inherit;
   font-size: ${props => BUTTON_TYPOGRAPHY[props.size].fontSize};
   font-weight: ${props => BUTTON_TYPOGRAPHY[props.size].fontWeight};
   line-height: ${adjustAnchorButtonHeight};
   height: ${adjustAnchorButtonHeight};
   min-width: ${props => BUTTON_SIZES[props.size].minWidth};
   padding: ${props => BUTTON_PADDING[props.size]};
   color: ${props => stylesByButtonType[props.buttonType][props.buttonColor].default.fontColor};
   background-color: ${props =>
      stylesByButtonType[props.buttonType][props.buttonColor].default.backgroundColor};
   border: ${props => stylesByButtonType[props.buttonType][props.buttonColor].default.border};
   border-radius: ${borderRadius.md};
   display: inline-block;
   text-decoration: none;
   text-align: center;

   svg {
      vertical-align: text-bottom;
   }

   > :not(:first-child) {
      margin-left: ${space[2]};
   }

   &:hover {
      background-color: ${props =>
         stylesByButtonType[props.buttonType][props.buttonColor].hover.backgroundColor};
      border: ${props => stylesByButtonType[props.buttonType][props.buttonColor].hover.border};
      color: ${props => stylesByButtonType[props.buttonType][props.buttonColor].hover.fontColor};
      text-decoration: none;
   }

   &:focus {
      outline: none;
      background-color: ${props =>
         stylesByButtonType[props.buttonType][props.buttonColor].focus.backgroundColor};
      border: ${props => stylesByButtonType[props.buttonType][props.buttonColor].focus.border};
      color: ${props => stylesByButtonType[props.buttonType][props.buttonColor].focus.fontColor};
   }

   &:disabled {
      cursor: not-allowed;
      color: ${color.gray[500]};
      background-color: rgba(0, 35, 70, 0.1);
      border: none;
   }

   &:visited {
      color: ${props => stylesByButtonType[props.buttonType][props.buttonColor].default.fontColor};
   }

   @media (max-width: ${props => props.mediaBreakpoint}) {
      height: ${props => BUTTON_SIZES[props.size !== 'default' ? props.size : 'large'].height};
   }
`;

export interface ButtonProps {
   size?: ButtonSize;
   buttonType?: ButtonType;
   buttonColor?: ButtonColor;
   mediaBreakpoint?: string;
   href?: string;
   disabled?: boolean;
}

function validateButtonProps(props: ButtonProps): ButtonProps {
   let buttonColor = props.buttonColor;
   if (props.buttonType !== 'dark' && props.buttonType !== 'light') {
      buttonColor = 'default';
   }

   return { ...props, buttonColor };
}

const Button = (
   props: ButtonProps &
      React.ButtonHTMLAttributes<HTMLButtonElement> &
      React.AnchorHTMLAttributes<HTMLAnchorElement>
): JSX.Element => {
   props = validateButtonProps(props);

   if (props.href && !props.disabled) {
      return <ButtonAnchor {...props} />;
   }

   // Anchor tags cannot be disabled via the attribute so always use ButtonInput when disabled
   return <ButtonInput {...props} />;
};

const defaultProps: ButtonProps = {
   size: 'default',
   buttonType: 'default',
   buttonColor: 'default',
   mediaBreakpoint: '0',
};
Button.defaultProps = defaultProps;

export default Button;
