import * as React from 'react';
import { Alert, AlertIcon, AlertDescription, AlertTitle, Box, CloseButton } from '@chakra-ui/react';
import { initializeChakraComponent } from 'Shared/chakra-initialize';

export enum AlertClosableType {
   success = 'success',
   warning = 'warning',
   error = 'error',
   info = 'info',
}

interface AlertClosableProps {
   type: AlertClosableType;
   heading?: string;
   text: string;
   onClose: any;
}

export const AlertClosable = (props: AlertClosableProps) => {
   if (!props.text) {
      return <Box />;
   }

   const alertStyles = {
      height: '42px',
      borderRadius: '5px',
      padding: '10px',
   };

   return (
      <Alert status={props.type} sx={alertStyles}>
         <AlertIcon />
         <Box>
            {props.heading && <AlertTitle>{props.heading}</AlertTitle>}
            <AlertDescription>{props.text}</AlertDescription>
         </Box>
         <CloseButton
            alignSelf="flex-start"
            position="relative"
            right={-1}
            top={-1}
            marginLeft="auto"
            onClick={props.onClose}
         />
      </Alert>
   );
};

initializeChakraComponent('AlertClosable', AlertClosable);
