import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpoint } from '@dozuki/web-js/primitives';

const BreakpointsContext = React.createContext();

function withBreakpointsContext(Component, custom_queries = {}) {
   return props => {
      const isDesktop = useMediaQuery({ minWidth: breakpoint.lg });
      const isTabletDesktop = useMediaQuery({ minWidth: breakpoint.md });
      const custom_breakpoints = Object.entries(custom_queries).reduce((result, [key, val]) => {
         const custom_breakpoint = useMediaQuery(val);
         result[key] = () => custom_breakpoint;
         return result;
      }, {});

      const breakpoints = {
         isDesktop: () => isDesktop,
         isTabletDesktop: () => isTabletDesktop,
         isTablet: () => isTabletDesktop && !isDesktop,
         isMobileTablet: () => !isDesktop,
         isMobile: () => !isTabletDesktop,
         ...custom_breakpoints,
      };

      return (
         <BreakpointsContext.Provider value={breakpoints}>
            <Component {...props} />
         </BreakpointsContext.Provider>
      );
   };
}

function withBreakpoints(Component) {
   return props => {
      const breakpoints = React.useContext(BreakpointsContext);
      return <Component breakpoints={breakpoints} {...props} />;
   };
}

export { withBreakpoints, withBreakpointsContext, BreakpointsContext };
