import * as React from 'react';
import color from './color';

/**
 * These components are standins for the core components constants that are
 * coming with https://github.com/iFixit/dozuki/issues/812, where we'll focus
 * on making them as configurable as possible.
 */
export const CaretUp = ({ fillColor = color.gray[4], size = 16, ...props }) => {
   return (
      <svg
         width={size}
         height={size}
         viewBox="0 0 16 16"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
         {...props}
      >
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 4C5 3.85 4.93 3.72 4.83 3.63L0.83 0.13C0.74 0.0500001 0.63 0 0.5 0C0.22 0 0 0.22 0 0.5V7.5C0 7.78 0.22 8 0.5 8C0.63 8 0.74 7.95 0.83 7.87L4.83 4.37C4.93 4.28 5 4.15 5 4Z"
            transform="translate(4 10) rotate(-90)"
            fill={fillColor}
         />
      </svg>
   );
};

export const CaretDown = ({ fillColor = color.gray[4], size = 16, ...props }) => {
   return (
      <svg
         width={size}
         height={size}
         viewBox="0 0 16 16"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
         {...props}
      >
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.5 0C4.37 0 4.26 0.0500001 4.17 0.13L0.17 3.63C0.0700001 3.72 0 3.85 0 4C0 4.15 0.0700001 4.28 0.17 4.37L4.17 7.87C4.26 7.95 4.37 8 4.5 8C4.78 8 5 7.78 5 7.5V0.5C5 0.22 4.78 0 4.5 0Z"
            transform="translate(4 11) rotate(-90)"
            fill={fillColor}
         />
      </svg>
   );
};

export const CaretRight = ({ fillColor = color.gray[4], size = 16, ...props }) => (
   <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M6.5 4C6.22 4 6 4.22 6 4.5L6 11.5C6 11.78 6.22 12 6.5 12C6.63 12 6.74 11.95 6.83 11.87L10.83 8.37C10.93 8.28 11 8.15 11 8C11 7.85 10.93 7.72 10.83 7.63L6.83 4.13C6.74 4.05 6.63 4 6.5 4Z"
         fill={fillColor}
      />
   </svg>
);

export const Check = ({ fillColor = color.gray[4], size = 16, ...props }) => {
   return (
      <svg
         width={size}
         height={size}
         viewBox="0 0 16 16"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
         {...props}
      >
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 3C14.72 3 14.47 3.11 14.29 3.29L7 10.59L3.71 7.29C3.53 7.11 3.28 7 3 7C2.45 7 2 7.45 2 8C2 8.28 2.11 8.53 2.29 8.71L6.29 12.71C6.47 12.89 6.72 13 7 13C7.28 13 7.53 12.89 7.71 12.71L15.71 4.71C15.89 4.53 16 4.28 16 4C16 3.45 15.55 3 15 3Z"
            fill={fillColor}
         />
      </svg>
   );
};

export const RightArrow = ({ fillColor = color.gray[4], size = 16, ...props }) => {
   return (
      <svg
         width={size}
         height={size}
         viewBox="0 0 16 16"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
         {...props}
      >
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.71 7.3L9.71 2.3C9.53 2.11 9.28 2 9 2C8.45 2 8 2.45 8 3C8 3.28 8.11 3.53 8.29 3.71L11.58 7H2C1.45 7 1 7.45 1 8C1 8.55 1.45 9 2 9H11.59L8.3 12.29C8.12 12.47 8.01 12.72 8.01 13C8.01 13.55 8.46 14 9.01 14C9.29 14 9.54 13.89 9.72 13.71L14.72 8.71C14.9 8.53 15.01 8.28 15.01 8C15.01 7.72 14.89 7.48 14.71 7.3Z"
            fill={fillColor}
         />
      </svg>
   );
};

export const Clock = ({ fillColor = color.gray[4], size = 16, ...props }) => (
   <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M8 0C3.58 0 0 3.58 0 8C0 12.42 3.58 16 8 16C12.42 16 16 12.42 16 8C16 3.58 12.42 0 8 0ZM8 14C4.69 14 2 11.31 2 8C2 4.69 4.69 2 8 2C11.31 2 14 4.69 14 8C14 11.31 11.31 14 8 14ZM9 7.59V4C9 3.45 8.55 3 8 3C7.45 3 7 3.45 7 4V8C7 8.28 7.11 8.53 7.29 8.71L9.29 10.71C9.47 10.89 9.72 11 10 11C10.55 11 11 10.55 11 10C11 9.72 10.89 9.47 10.71 9.29L9 7.59Z"
         fill={fillColor}
      />
   </svg>
);

export const SmallCross = ({ fillColor, size = 16, ...props }) => {
   return (
      <svg
         width={size}
         height={size}
         viewBox="0 0 8 8"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
         {...props}
      >
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.41 4L7.7 1.71C7.89 1.53 8 1.28 8 1C8 0.45 7.55 0 7 0C6.72 0 6.47 0.11 6.29 0.29L4 2.59L1.71 0.29C1.53 0.11 1.28 0 1 0C0.45 0 0 0.45 0 1C0 1.28 0.11 1.53 0.29 1.71L2.59 4L0.3 6.29C0.11 6.47 0 6.72 0 7C0 7.55 0.45 8 1 8C1.28 8 1.53 7.89 1.71 7.71L4 5.41L6.29 7.7C6.47 7.89 6.72 8 7 8C7.55 8 8 7.55 8 7C8 6.72 7.89 6.47 7.71 6.29L5.41 4Z"
            fill={fillColor}
         />
      </svg>
   );
};

export const Cross = ({ fillColor = color.gray[4], size = 16, ...props }) => (
   <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      data-testid="cross"
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M9.41 8L12.7 4.71C12.89 4.53 13 4.28 13 4C13 3.45 12.55 3 12 3C11.72 3 11.47 3.11 11.29 3.29L8 6.59L4.71 3.29C4.53 3.11 4.28 3 4 3C3.45 3 3 3.45 3 4C3 4.28 3.11 4.53 3.29 4.71L6.59 8L3.3 11.29C3.11 11.47 3 11.72 3 12C3 12.55 3.45 13 4 13C4.28 13 4.53 12.89 4.71 12.71L8 9.41L11.29 12.7C11.47 12.89 11.72 13 12 13C12.55 13 13 12.55 13 12C13 11.72 12.89 11.47 12.71 11.29L9.41 8Z"
         fill={fillColor}
      />
   </svg>
);

export const ChevronDown = ({ fillColor, size = 16, ...props }) => (
   <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M12 6C11.72 6 11.47 6.11 11.29 6.29L8 9.59L4.71 6.29C4.53 6.11 4.28 6 4 6C3.45 6 3 6.45 3 7C3 7.28 3.11 7.53 3.29 7.71L7.29 11.71C7.47 11.89 7.72 12 8 12C8.28 12 8.53 11.89 8.71 11.71L12.71 7.71C12.89 7.53 13 7.28 13 7C13 6.45 12.55 6 12 6Z"
         fill={fillColor}
      />
   </svg>
);

export const ChevronUp = ({ fillColor, size = 16, ...props }) => (
   <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M12.71 8.29L8.71 4.29C8.53 4.11 8.28 4 8 4C7.72 4 7.47 4.11 7.29 4.29L3.29 8.29C3.11 8.47 3 8.72 3 9C3 9.55 3.45 10 4 10C4.28 10 4.53 9.89 4.71 9.71L8 6.41L11.29 9.7C11.47 9.89 11.72 10 12 10C12.55 10 13 9.55 13 9C13 8.72 12.89 8.47 12.71 8.29Z"
         fill={fillColor}
      />
   </svg>
);

export const Help = ({ fillColor = color.gray[4], size = 16, ...props }) => (
   <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
         fill={fillColor}
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M7 6C7 5.49048 7.1582 5.29491 7.26514 5.20572C7.4043 5.0898 7.64856 5 8 5C8.42944 5 8.64746 5.13531 8.76404 5.26097C8.89197 5.39885 9 5.63712 9 6C9 6.56032 8.78235 6.80341 8.29285 7.29289L8.26672 7.31906C7.7561 7.82924 7 8.68094 7 10H9C9 9.43968 9.21759 9.19659 9.70709 8.70711L9.73328 8.68094C10.2439 8.17076 11 7.41528 11 6C11 5.24486 10.7708 4.48312 10.23 3.9005C9.67804 3.30566 8.89612 3 8 3C7.35144 3 6.5957 3.1602 5.98486 3.66928C5.3418 4.20509 5 5.00952 5 6H7ZM9 12V11H7V12C7 12.5523 7.44775 13 8 13C8.55225 13 9 12.5523 9 12Z"
         fill={fillColor}
      />
   </svg>
);

export const User = ({ fillColor, size = 16, ...props }) => (
   <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
   >
      <path
         d="M2 16C3 16 3 16.0005 3 16.0009C3 16.0011 3 16.0015 3 16.0018C3 16.0024 3 16.003 2.99999 16.0036C2.99999 16.0047 2.99998 16.0058 2.99997 16.0068C2.99996 16.0089 2.99994 16.0107 2.99991 16.0122C2.99987 16.0153 2.99981 16.0175 2.99977 16.0188C2.99969 16.0213 2.99968 16.0204 2.99995 16.0162C3.00051 16.0077 3.00219 15.987 3.00663 15.9564C3.01563 15.8946 3.03512 15.7984 3.07616 15.6855C3.1563 15.4651 3.31641 15.1835 3.65744 14.949L2.52438 13.301C1.7745 13.8165 1.38916 14.4724 1.19657 15.002C1.10125 15.2641 1.05255 15.496 1.02746 15.6686C1.01485 15.7552 1.00801 15.8282 1.00431 15.8842C1.00246 15.9123 1.00138 15.9362 1.00077 15.9556C1.00046 15.9653 1.00027 15.9739 1.00015 15.9813C1.0001 15.985 1.00006 15.9884 1.00003 15.9915C1.00002 15.9931 1.00001 15.9946 1.00001 15.996C1.00001 15.9967 1 15.9974 1 15.998C1 15.9984 1 15.9989 1 15.999C1 15.9995 1 16 2 16ZM3.65744 14.949C4.49911 14.3704 6.10737 14 8 14V12C5.96486 12 3.86453 12.3796 2.52438 13.301L3.65744 14.949ZM8 14C9.89263 14 11.5009 14.3704 12.3426 14.949L13.4756 13.301C12.1355 12.3796 10.0351 12 8 12V14ZM12.3426 14.949C12.6836 15.1835 12.8437 15.4651 12.9238 15.6855C12.9649 15.7984 12.9844 15.8946 12.9934 15.9564C12.9978 15.987 12.9995 16.0077 13 16.0162C13.0003 16.0204 13.0003 16.0213 13.0002 16.0188C13.0002 16.0175 13.0001 16.0153 13.0001 16.0122C13.0001 16.0107 13 16.0089 13 16.0068C13 16.0058 13 16.0047 13 16.0036C13 16.003 13 16.0024 13 16.0018C13 16.0015 13 16.0011 13 16.0009C13 16.0005 13 16 14 16C15 16 15 15.9995 15 15.999C15 15.9989 15 15.9984 15 15.998C15 15.9974 15 15.9967 15 15.996C15 15.9946 15 15.9931 15 15.9915C14.9999 15.9884 14.9999 15.985 14.9998 15.9813C14.9997 15.9739 14.9995 15.9653 14.9992 15.9556C14.9986 15.9362 14.9975 15.9123 14.9957 15.8842C14.992 15.8282 14.9851 15.7552 14.9725 15.6686C14.9474 15.496 14.8988 15.2641 14.8034 15.002C14.6108 14.4724 14.2255 13.8165 13.4756 13.301L12.3426 14.949Z"
         fill={fillColor}
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M9.14858 8.05369C9.65273 7.27342 10 6.15396 10 5C10 3.57466 9.63693 2.85668 9.33511 2.5179C9.05437 2.20278 8.63727 2 8 2C7.36273 2 6.94563 2.20278 6.66489 2.5179C6.36307 2.85668 6 3.57466 6 5C6 6.15396 6.34727 7.27342 6.85142 8.05369C7.38312 8.87661 7.84531 9 8 9C8.15469 9 8.61688 8.87661 9.14858 8.05369ZM12 5C12 8.03757 10.2092 11 8 11C5.79083 11 4 8.03757 4 5C4 1.5 5.79083 0 8 0C10.2092 0 12 1.5 12 5Z"
         fill={fillColor}
      />
   </svg>
);

export const Layers = ({ fillColor = color.gray[4], size = 16, ...props }) => (
   <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M7.55279 0.105573C7.83431 -0.0351909 8.16569 -0.0351909 8.44721 0.105573L15.4472 3.60557C15.786 3.77496 16 4.12123 16 4.5C16 4.87877 15.786 5.22504 15.4472 5.39443L8.44721 8.89443C8.16569 9.03519 7.83431 9.03519 7.55279 8.89443L0.552786 5.39443C0.214002 5.22504 0 4.87877 0 4.5C0 4.12123 0.214002 3.77496 0.552786 3.60557L7.55279 0.105573ZM3.23607 4.5L8 6.88197L12.7639 4.5L8 2.11803L3.23607 4.5Z"
         fill={fillColor}
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M0.105548 11.0528C0.352537 10.5588 0.95321 10.3586 1.44719 10.6055L7.99998 13.8819L14.5528 10.6055C15.0467 10.3586 15.6474 10.5588 15.8944 11.0528C16.1414 11.5467 15.9412 12.1474 15.4472 12.3944L8.44719 15.8944C8.16566 16.0352 7.83429 16.0352 7.55276 15.8944L0.552761 12.3944C0.0587828 12.1474 -0.141442 11.5467 0.105548 11.0528Z"
         fill={fillColor}
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M0.105548 7.55276C0.352537 7.05878 0.95321 6.85856 1.44719 7.10555L7.99998 10.3819L14.5528 7.10555C15.0467 6.85856 15.6474 7.05878 15.8944 7.55276C16.1414 8.04674 15.9412 8.64741 15.4472 8.8944L8.44719 12.3944C8.16566 12.5352 7.83429 12.5352 7.55276 12.3944L0.552761 8.8944C0.0587828 8.64741 -0.141442 8.04674 0.105548 7.55276Z"
         fill={fillColor}
      />
   </svg>
);

export const More = ({ fillColor, size = 16, ...props }) => (
   <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M3.41437 6.58575C4.19537 7.367 4.19537 8.63312 3.41437 9.41437C2.63312 10.1954 1.367 10.1954 0.585754 9.41437C-0.195251 8.63312 -0.195251 7.367 0.585754 6.58575C1.367 5.80475 2.63312 5.80475 3.41437 6.58575ZM15.4144 6.58575C16.1954 7.367 16.1954 8.63312 15.4144 9.41437C14.6331 10.1954 13.367 10.1954 12.5858 9.41437C11.8047 8.63312 11.8047 7.367 12.5858 6.58575C13.367 5.80475 14.6331 5.80475 15.4144 6.58575ZM9.41437 9.41437C10.1954 8.63312 10.1954 7.367 9.41437 6.58575C8.63312 5.80475 7.367 5.80475 6.58575 6.58575C5.80475 7.367 5.80475 8.63312 6.58575 9.41437C7.367 10.1954 8.63312 10.1954 9.41437 9.41437Z"
         fill={fillColor}
      />
   </svg>
);

export const Frames = ({ fillColors, size = 64, ...props }) => {
   if (fillColors.length !== 3) {
      console.error('Must probide an array of 3 colors');
   }

   return (
      <svg
         width={size}
         height={size}
         viewBox="0 0 64 64"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
         {...props}
      >
         <path d="M32 0L64 16L32 32L0 16L32 0Z" fill={fillColors[0]} />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 28L0 32L32 48L64 32L56 28L32 40L8 28Z"
            fill={fillColors[1]}
         />
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 44L0 48L32 64L64 48L56 44L32 56L8 44Z"
            fill={fillColors[2]}
         />
      </svg>
   );
};

export const List = ({ fillColor = color.gray[4], size = 24, ...props }) => (
   <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M8 5C7.44772 5 7 5.44775 7 6C7 6.55225 7.44772 7 8 7H21C21.5523 7 22 6.55225 22 6C22 5.44775 21.5523 5 21 5H8ZM8 11C7.44772 11 7 11.4478 7 12C7 12.5522 7.44772 13 8 13H21C21.5523 13 22 12.5522 22 12C22 11.4478 21.5523 11 21 11H8ZM7 18C7 17.4478 7.44772 17 8 17H21C21.5523 17 22 17.4478 22 18C22 18.5522 21.5523 19 21 19H8C7.44772 19 7 18.5522 7 18ZM3 13C3.55228 13 4 12.5522 4 12C4 11.4478 3.55228 11 3 11C2.44772 11 2 11.4478 2 12C2 12.5522 2.44772 13 3 13ZM4 6C4 6.55225 3.55228 7 3 7C2.44772 7 2 6.55225 2 6C2 5.44775 2.44772 5 3 5C3.55228 5 4 5.44775 4 6ZM3 19C3.55228 19 4 18.5522 4 18C4 17.4478 3.55228 17 3 17C2.44772 17 2 17.4478 2 18C2 18.5522 2.44772 19 3 19Z"
         fill={fillColor}
      />
   </svg>
);

export const Users = ({ fillColor = color.gray[4], size = 24, ...props }) => (
   <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M9.5 17C7.52421 17 5.8308 17.5865 4.90846 18.562C4.48394 19.011 4.24667 19.6042 4.12091 20.1363C4.05963 20.3955 4.02908 20.6224 4.01403 20.7816C4.00655 20.8607 4.00303 20.9217 4.00139 20.9599C4.00057 20.979 4.00022 20.9923 4.00008 20.9992L3.99999 21.0046C3.99998 21.0047 3.99999 21.0044 3.99999 21.0036L4 21.0021L4 21.0006C4 21.0003 4 21 3 21C2 21 2 20.9997 2 20.9994L2 20.9972L2.00002 20.9937L2.00009 20.9845L2.0005 20.9581C2.00093 20.9369 2.00174 20.9087 2.00323 20.8741C2.0062 20.8049 2.01192 20.7096 2.02291 20.5934C2.04479 20.3619 2.08809 20.042 2.17454 19.6762C2.34424 18.9583 2.69787 17.989 3.45517 17.188C4.89647 15.6635 7.22071 15 9.5 15C11.7793 15 14.1035 15.6635 15.5448 17.188C16.3021 17.989 16.6558 18.9583 16.8255 19.6762C16.9119 20.042 16.9552 20.3619 16.9771 20.5934C16.9881 20.7096 16.9938 20.8049 16.9968 20.8741C16.9983 20.9087 16.9991 20.9369 16.9995 20.9581L16.9999 20.9845L17 20.9937L17 20.9972L17 20.9987C17 20.999 17 21 16 21C15 21 15 21.0003 15 21.0006L15 21.0021L15 21.0036L14.9999 20.9992C14.9998 20.9923 14.9994 20.979 14.9986 20.9599C14.997 20.9217 14.9935 20.8607 14.986 20.7816C14.9709 20.6224 14.9404 20.3955 14.8791 20.1363C14.7533 19.6042 14.5161 19.011 14.0915 18.562C13.1692 17.5865 11.4758 17 9.5 17Z"
         fill={fillColor}
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M9.5 5C8.21337 5 7 6.01797 7 8.05C7 9.09642 7.33383 10.1363 7.85076 10.8928C8.37767 11.6638 8.98384 12 9.5 12C10.0162 12 10.6223 11.6638 11.1492 10.8928C11.6662 10.1363 12 9.09642 12 8.05C12 6.01797 10.7866 5 9.5 5ZM5 8.05C5 5.11147 6.92063 3 9.5 3C12.0794 3 14 5.11147 14 8.05C14 9.48887 13.5503 10.924 12.8005 12.0212C12.0607 13.1038 10.9168 14 9.5 14C8.08316 14 6.93933 13.1038 6.1995 12.0212C5.44968 10.924 5 9.48887 5 8.05Z"
         fill={fillColor}
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M17.5 17C17 16 16 15 16 15C17.8072 15 19.6763 15.5639 20.8405 16.8822C21.4517 17.5743 21.7305 18.402 21.8628 19.0013C21.9307 19.3089 21.9647 19.5777 21.9819 19.7723C21.9906 19.8701 21.9951 19.9505 21.9974 20.0092C21.9986 20.0386 21.9992 20.0626 21.9996 20.0809L21.9999 20.1038L22 20.1119L22 20.1151L22 20.1164C22 20.1164 22 20.1176 21 20.1176C20.9045 20.1176 20 20.1212 20 20.1212C20 19.5 19.5 17.5 17.5 17Z"
         fill={fillColor}
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M16 6C15.7549 6 15.53 6.05722 15.3298 6.16219L14.401 4.39091C14.8904 4.13431 15.4344 4 16 4C18.1925 4 19.6923 5.90323 19.6923 8.33529C19.6923 9.54023 19.3412 10.7476 18.7466 11.6791C18.1675 12.5865 17.2261 13.4118 16 13.4118C15.3354 13.4118 14.7443 13.1624 14.262 12.7974L15.4688 11.2026C15.6761 11.3594 15.8538 11.4118 16 11.4118C16.2608 11.4118 16.6656 11.2222 17.0608 10.603C17.4407 10.0079 17.6923 9.17707 17.6923 8.33529C17.6923 6.67396 16.7813 6 16 6Z"
         fill={fillColor}
      />
   </svg>
);

export const Pencil = ({ fillColor = color.gray[4], size = 16, ...props }) => (
   <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M1.92377 11.1906C1.97406 11.0648 2.04938 10.9506 2.1452 10.8549L9.99994 3.00003L11.1308 4.13086L3.96869 11.293C3.7735 11.4882 3.7735 11.8047 3.96869 12C4.16388 12.1953 4.48053 12.1953 4.67572 12L11.8378 4.83789L12.9999 6L5.14508 13.8549C5.04938 13.9506 4.93512 14.0259 4.80939 14.0762L0.433289 15.8267C0.269958 15.892 0.107971 15.73 0.173279 15.5667L1.92377 11.1906ZM13.9999 5L10.9999 2.00003L12.2928 0.707123C12.6833 0.316589 13.3165 0.316589 13.707 0.707123L15.2928 2.29291C15.6833 2.68341 15.6833 3.31659 15.2928 3.70709L13.9999 5Z"
         fill={fillColor}
      />
   </svg>
);

export const Play = ({ fillColor = color.gray[4], size = 16, ...props }) => (
   <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M5 11.2536L10.9367 8.00066L5 4.7502V11.2536ZM3 3.0626V12.9418C3 13.7017 3.81411 14.1839 4.48053 13.8188L13.4989 8.87731C14.1916 8.49773 14.1915 7.50256 13.4986 7.1232L4.48025 2.18547C3.81384 1.82059 3 2.30284 3 3.0626Z"
         fill={fillColor}
      />
   </svg>
);

export const Plus = ({ fillColor = color.gray[4], size = 16, ...props }) => (
   <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M11 5H7V1C7 0.45 6.55 0 6 0C5.45 0 5 0.45 5 1V5H1C0.45 5 0 5.45 0 6C0 6.55 0.45 7 1 7H5V11C5 11.55 5.45 12 6 12C6.55 12 7 11.55 7 11V7H11C11.55 7 12 6.55 12 6C12 5.45 11.55 5 11 5Z"
         fill={fillColor}
      />
   </svg>
);

export const Refresh = ({ fillColor = color.gray[4], size = 16, ...props }) => (
   <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M13 7.99999C13 9.83995 12.0067 11.449 10.5223 12.3183C10.0457 12.5974 9.88558 13.21 10.1647 13.6866C10.4438 14.1631 11.0563 14.3232 11.5329 14.0442C13.6048 12.8308 15 10.5789 15 7.99999C15 5.74603 13.9336 3.74067 12.2819 2.462C11.8452 2.12392 11.2171 2.20387 10.879 2.64058C10.5409 3.07729 10.6209 3.70539 11.0576 4.04348C12.2411 4.95968 13 6.39097 13 7.99999Z"
         fill={fillColor}
      />
      <path
         d="M7.48202 14.6034L3.73067 15.5883C3.32326 15.6952 2.97425 15.2814 3.14847 14.8979L5.17384 10.4397C5.34807 10.0562 5.88938 10.0468 6.07683 10.424L7.80281 13.8972C7.94386 14.1811 7.78859 14.5229 7.48202 14.6034Z"
         fill={fillColor}
      />
      <path
         d="M8.51817 1.39657L12.2695 0.411658C12.6769 0.304693 13.0259 0.718579 12.8517 1.10208L10.8263 5.56027C10.6521 5.94377 10.1108 5.95318 9.92336 5.57596L8.19738 2.10269C8.05633 1.81884 8.2116 1.47706 8.51817 1.39657Z"
         fill={fillColor}
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M3 8.00015C3 6.14522 4.00959 4.52488 5.51405 3.66077C5.99296 3.3857 6.15821 2.77448 5.88314 2.29557C5.60807 1.81666 4.99685 1.65141 4.51794 1.92648C2.41795 3.13264 1 5.4002 1 8.00015C1 10.2541 2.0664 12.2595 3.7181 13.5381C4.15481 13.8762 4.78291 13.7963 5.12099 13.3596C5.45907 12.9228 5.37912 12.2948 4.94241 11.9567C3.75892 11.0405 3 9.60917 3 8.00015Z"
         fill={fillColor}
      />
   </svg>
);

export const Drag = ({ fillColor = color.gray[4], size = 16, ...props }) => (
   <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M2.5 9C1.675 9 1 9.675 1 10.5C1 11.325 1.675 12 2.5 12C3.325 12 4 11.325 4 10.5C4 9.675 3.325 9 2.5 9ZM2.5 4C1.675 4 1 4.675 1 5.5C1 6.325 1.675 7 2.5 7C3.325 7 4 6.325 4 5.5C4 4.675 3.325 4 2.5 4ZM8 9C7.175 9 6.5 9.675 6.5 10.5C6.5 11.325 7.175 12 8 12C8.825 12 9.5 11.325 9.5 10.5C9.5 9.675 8.825 9 8 9ZM13.5 4C12.675 4 12 4.675 12 5.5C12 6.325 12.675 7 13.5 7C14.325 7 15 6.325 15 5.5C15 4.675 14.325 4 13.5 4ZM13.5 9C12.675 9 12 9.675 12 10.5C12 11.325 12.675 12 13.5 12C14.325 12 15 11.325 15 10.5C15 9.675 14.325 9 13.5 9ZM8 4C7.175 4 6.5 4.675 6.5 5.5C6.5 6.325 7.175 7 8 7C8.825 7 9.5 6.325 9.5 5.5C9.5 4.675 8.825 4 8 4Z"
         fill={fillColor}
      />
   </svg>
);

export const Trash = ({ fillColor = color.gray[4], size = 16, ...props }) => (
   <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M6 0C4.89551 0 4 0.895386 4 2V3H2C1.44775 3 1 3.44775 1 4C1 4.55225 1.44775 5 2 5V13C2 14.6569 3.34326 16 5 16H11C12.6567 16 14 14.6569 14 13V5C14.5522 5 15 4.55225 15 4C15 3.44775 14.5522 3 14 3H12V2C12 0.895386 11.1045 0 10 0H6ZM10 3V2H6V3H10ZM5 5H4V13C4 13.5522 4.44775 14 5 14H11C11.5522 14 12 13.5522 12 13V5H11H5ZM6.5 6C6.77612 6 7 6.26111 7 6.58337V12.4166C7 12.7389 6.77612 13 6.5 13C6.41382 13 6.33276 12.9746 6.26196 12.9298C6.10596 12.8311 6 12.6383 6 12.4166V6.58337C6 6.4812 6.02246 6.38525 6.06201 6.30176C6.14722 6.12183 6.31128 6 6.5 6ZM10 6.58337C10 6.26111 9.77612 6 9.5 6C9.22388 6 9 6.26111 9 6.58337V12.4166C9 12.7389 9.22388 13 9.5 13C9.77612 13 10 12.7389 10 12.4166V6.58337Z"
         fill={fillColor}
      />
   </svg>
);

export const AlertCircle = ({ fillColor = color.gray[4], size = 16, ...props }) => (
   <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM9 11C9 11.5523 8.55229 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55229 10 9 10.4477 9 11ZM9 8C9 8.55229 8.55229 9 8 9C7.44772 9 7 8.55229 7 8V5C7 4.44772 7.44772 4 8 4C8.55229 4 9 4.44772 9 5V8Z"
         fill={fillColor}
      />
   </svg>
);

export const Minus = ({ fillColor = color.gray[4], size = 16, ...props }) => (
   <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M13 7C5.44345 7 10.5565 7 3 7C2.45 7 2 7.45 2 8C2 8.55 2.45 9 3 9C10.5565 9 5.44345 9 13 9C13.55 9 14 8.55 14 8C14 7.45 13.55 7 13 7Z"
         fill={fillColor}
      />
   </svg>
);

export const FastForward = ({ fillColor = color.gray[4], size = 16, ...props }) => (
   <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M9 8C9 7.72 8.89 7.47 8.71 7.29L4.71 3.29C4.53 3.11 4.28 3 4 3C3.45 3 3 3.45 3 4C3 4.28 3.11 4.53 3.29 4.71L6.59 8L3.3 11.29C3.11 11.47 3 11.72 3 12C3 12.55 3.45 13 4 13C4.28 13 4.53 12.89 4.71 12.71L8.71 8.71C8.89 8.53 9 8.28 9 8ZM13.71 7.29L9.71 3.29C9.53 3.11 9.28 3 9 3C8.45 3 8 3.45 8 4C8 4.28 8.11 4.53 8.29 4.71L11.59 8L8.3 11.29C8.11 11.47 8 11.72 8 12C8 12.55 8.45 13 9 13C9.28 13 9.53 12.89 9.71 12.71L13.71 8.71C13.89 8.53 14 8.28 14 8C14 7.72 13.89 7.47 13.71 7.29Z"
         fill={fillColor}
      />
   </svg>
);
