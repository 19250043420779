import * as React from 'react';
import { number, string } from 'prop-types';
import styled from 'styled-components';
import constants from 'Shared/core-components/constants';

const { color } = constants;

const BackgroundCircle = styled.circle`
   fill: none;
   stroke: rgba(36, 44, 51, 0.4);
   stroke-opacity: 0.3;
`;

const ProgressCircle = styled.circle`
   fill: none;
   stroke: ${props => props.progressColor};
   stroke-dasharray: ${props => props.strokeDasharray};
   stroke-dashoffset: ${props => props.strokeDashoffset};
`;

const CircularProgress = ({
   progress,
   progressColor = color.white,
   size = 32,
   strokeWidth = 4,
   ...props
}) => {
   // SVG centers the stroke width on the radius, subtract out so circle fits in square
   const radius = (size - strokeWidth) / 2;
   // Enclose cicle in a circumscribing square
   const viewBox = `0 0 ${size} ${size}`;
   // Arc length at 100% coverage is the circle circumference
   const dashArray = radius * Math.PI * 2;
   // Scale 100% coverage overlay with the actual percent
   const dashOffset = dashArray - (dashArray * progress) / 100;

   return (
      <svg width={size} height={size} viewBox={viewBox} {...props}>
         <BackgroundCircle
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth={`${strokeWidth}px`}
         />
         <ProgressCircle
            progressColor={progressColor}
            cx={size / 2}
            cy={size / 2}
            r={radius}
            strokeWidth={`${strokeWidth}px`}
            // Start progress marker at 12 O'Clock
            transform={`rotate(-90 ${size / 2} ${size / 2})`}
            strokeDasharray={dashArray}
            strokeDashoffset={dashOffset}
         />
      </svg>
   );
};

CircularProgress.propTypes = {
   progress: number.isRequired,
   progressColor: string,
   size: number.isRequired,
   strokeWidth: number,
};

export default CircularProgress;
