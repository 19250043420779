import * as React from 'react';
import { string } from 'prop-types';
import styled from 'styled-components';
import constants from 'Shared/core-components/constants';

const { color, spacing, borderRadius } = constants;

const TextInput = styled.input`
   display: flex;
   width: 100%;
   height: 2.25rem;
   padding: ${spacing[0]} ${spacing[3]};
   font-size: 1rem;
   color: ${color.gray[7]};
   border: 1px solid ${color.gray[2]};
   border-radius: ${borderRadius[1]};

   &:placeholder-shown {
      color: ${color.gray[3]};
   }

   &:focus {
      outline: none;
      border-color: ${color.gray[3]};
   }

   &:disabled {
      color: ${color.gray[3]};
      background-color: ${color.gray[1]};
      border: none;
   }
`;

export default class Input extends React.Component {
   static propTypes = {
      type: string,
   };

   static defaultProps = {
      type: 'text',
   };

   get InputField() {
      let InputComponent = null;

      switch (this.props.type) {
         case 'number':
         case 'text':
         default:
            InputComponent = TextInput;
      }

      return InputComponent;
   }

   render() {
      const InputField = this.InputField;
      const { forwardedRef, ...props } = this.props;
      return <InputField ref={forwardedRef} {...props} />;
   }
}
