import * as React from 'react';
import styled from 'styled-components';
import constants from 'Shared/core-components/constants';
import { AlertCircle, Check } from 'core-icons';
import mapValues from 'lodash/mapValues';

const { borderRadius, color, spacing } = constants;

export enum AlertType {
   Default = 'default',
   Success = 'success',
   Warning = 'warning',
   Error = 'error',
}

const TYPES = {
   [AlertType.Default]: {
      Icon: AlertCircle,
      iconColor: color.gray[4],
      backgroundColor: color.gray[0],
      textColor: color.gray[7],
   },
   [AlertType.Success]: {
      Icon: Check,
      iconColor: color.green,
      backgroundColor: color.greenLight[3],
      textColor: color.greenDark[1],
   },
   [AlertType.Warning]: {
      Icon: AlertCircle,
      iconColor: color.yellow,
      backgroundColor: color.yellowLight[3],
      textColor: color.yellowDark[2],
   },
   [AlertType.Error]: {
      Icon: AlertCircle,
      iconColor: color.red,
      backgroundColor: color.redLight[3],
      textColor: color.redDark[1],
   },
};

interface AlertContainerProps {
   backgroundColor: string;
   textColor: string;
}

const AlertContainer = styled.div<AlertContainerProps>`
   display: flex;
   align-items: center;
   font-size: 0.875rem;
   color: ${props => props.textColor};
   padding: ${spacing[2]} ${spacing[3]};
   background-color: ${props => props.backgroundColor};
   border-radius: ${borderRadius[1]};
`;

const AlertHeading = styled.span`
   font-weight: bold;
`;

export type AlertProps = React.HTMLAttributes<HTMLDivElement> & {
   heading?: string;
   text: string;
   type: AlertType;
};

const AlertIcons = mapValues(
   TYPES,
   type =>
      styled(type.Icon)`
         margin-right: ${spacing[3]};
      `
);

const Alert = ({ heading, text, type, ...props }: AlertProps) => {
   const { Icon, iconColor, ...containerProps } = TYPES[type];
   const AlertIcon = AlertIcons[type];

   return (
      <AlertContainer data-type={type} {...containerProps} {...props}>
         <AlertIcon fillColor={iconColor} />
         {heading && <AlertHeading>{heading}</AlertHeading>}
         {heading && text && <>&#8202;&ndash;&#8202;</>}
         {text && <span>{text}</span>}
      </AlertContainer>
   );
};

export default Alert;
