import * as React from 'react';
import { string, func, bool, node } from 'prop-types';
import ReactModal from 'react-modal';
import styled from 'styled-components';
import coreComponentsConstants from 'Shared/core-components/constants';
import { Cross } from 'core-icons';

const { color, spacing, borderRadius, shadows } = coreComponentsConstants;

const ModalContentBox = styled.div`
   max-width: 54.5rem;
   margin: 0 auto;
   padding: 0;
   height: auto;
   text-align: center;
   position: relative;
`;

const ModalContent = styled.div`
   display: inline-block;
   position: relative;
   margin: auto;
   width: 54.5rem;
   max-width: 100%;
   background: ${color.white};
   border-radius: ${borderRadius[2]};
   box-shadow: ${shadows.dropShadow[3]};
`;

const ModalHeader = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   background-color: ${color.gray[0]};
   height: 2.5rem;
   padding: ${spacing[3]};
   border-top-left-radius: ${borderRadius[2]};
   border-top-right-radius: ${borderRadius[2]};
`;

const ModalTitle = styled.span`
   display: flex;
   font-size: 0.875rem;
   font-weight: bold;
   line-height: 1.0625rem;
   color: ${color.gray[3]};
`;

const ModalBody = styled.div`
   padding: ${spacing[5]};
`;

const ModalCloseButton = styled(Cross)`
   z-index: 10;
   cursor: pointer;
`;

export default class Modal extends React.Component {
   static propTypes = {
      title: string,
      isOpen: bool,
      children: node,

      /** Callback that is called when the modal is closing */
      onRequestClose: func,
   };

   static defaultProps = {
      title: '',
      isOpen: false,
      children: null,
      onRequestClose: () => {},
   };

   static setAppElement(elementIdentifier) {
      ReactModal.setAppElement(elementIdentifier);
   }

   render() {
      const { title, isOpen, children, onRequestClose, ...props } = this.props;

      return (
         <ReactModal
            isOpen={isOpen}
            {...props}
            className="modalBox"
            overlayClassName="modal-overlay"
         >
            <ModalContentBox>
               <ModalContent>
                  <ModalHeader>
                     <ModalTitle>{title}</ModalTitle>
                     <ModalCloseButton onClick={onRequestClose} />
                  </ModalHeader>
                  <ModalBody>{children}</ModalBody>
               </ModalContent>
            </ModalContentBox>
         </ReactModal>
      );
   }
}
