import * as React from 'react';
import { any, bool, func, oneOf } from 'prop-types';
import styled from 'styled-components';
import { borderRadius, color } from '@dozuki/web-js/primitives';
import { SmallCross } from 'core-icons';

const labelColors = {
   light: {
      black: {
         backgroundColor: color.gray[50],
         fillColor: color.gray[600],
      },
      blue: {
         backgroundColor: color.blue[50],
         fillColor: color.blue[600],
      },
      green: {
         backgroundColor: color.green[100],
         fillColor: color.green[600],
      },
      red: {
         backgroundColor: color.red[100],
         fillColor: color.red[600],
      },
      yellow: {
         backgroundColor: color.yellow[100],
         fillColor: color.yellow[700],
      },
   },
   dark: {
      black: {
         backgroundColor: color.gray[700],
         fillColor: color.white,
      },
      blue: {
         backgroundColor: color.blue[400],
         fillColor: color.white,
      },
      green: {
         backgroundColor: color.green[400],
         fillColor: color.white,
      },
      red: {
         backgroundColor: color.red[400],
         fillColor: color.white,
      },
      yellow: {
         backgroundColor: color.yellow[400],
         fillColor: color.gray[900],
      },
   },
};

const iconColors = {
   light: color.gray[900],
   dark: color.white,
};

const LabelContainer = styled.div`
   display: inline-block;
`;

const LabelText = styled.div`
   display: flex;
   align-items: center;
   font-size: ${props => (props.size === 'large' ? '0.8125rem' : '0.75rem')};
   padding: 1px ${props => (props.size === 'large' ? '0.375rem' : '0.25rem')};
   font-weight: bold;
   color: ${props => props.fillColor};
   background-color: ${props => props.backgroundColor};
   border-radius: ${borderRadius.md};
   text-shadow: ${props => props.theme === 'dark' && '0px 1px 0px rgba(0, 0, 0, 0.15)'};
`;

const CloseIcon = styled(SmallCross)`
   margin-left: 6px;
   opacity: ${props => (props.theme === 'light' ? 0.15 : 0.5)};
   cursor: pointer;
`;

const Label = ({
   color = 'black',
   closeable = false,
   theme = 'light',
   onClose = () => {},
   children,
   size = 'small',
   ...props
}) => (
   <LabelContainer {...props}>
      <LabelText theme={theme} size={size} {...labelColors[theme][color]}>
         {children}
         {closeable && (
            <CloseIcon fillColor={iconColors[theme]} size={8} theme={theme} onClick={onClose} />
         )}
      </LabelText>
   </LabelContainer>
);

Label.propTypes = {
   color: oneOf(['black', 'blue', 'green', 'red', 'yellow']),
   closeable: bool,
   size: oneOf(['small', 'large']),
   theme: oneOf(['light', 'dark']),
   onClose: func,
   children: any.isRequired,
};

export default Label;
