import * as React from 'react';
import styled from 'styled-components';
import { space, borderRadius } from '@dozuki/web-js/primitives';

type AvatarProps = {
   src: string;
   shape?: 'circle' | 'square';
   size?: 'small' | 'large';
};

const AvatarImage = styled.img<AvatarProps>`
   width: ${props => (props.size === 'small' ? space[7] : space[9])};
   height: ${props => (props.size === 'small' ? space[7] : space[9])};
   border-radius: ${props => (props.shape === 'circle' ? borderRadius.pill : borderRadius.md)};
`;

const Avatar = ({
   src,
   shape = 'circle',
   size = 'small',
   ...props
}: AvatarProps & React.HTMLAttributes<HTMLImageElement>) => (
   <AvatarImage src={src} shape={shape} size={size} {...props} />
);

export default Avatar;
