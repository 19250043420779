import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import constants from 'Shared/core-components/constants';
import RoundBadge from '../Badge/roundBadge';

const RoundBadgeButton = styled(RoundBadge)`
   cursor: pointer;
`;

const RoundIconButton = ({
   color = constants.color.gray[1],
   borderColor = constants.color.gray[1],
   size = 32,
   onClick,
   ...props
}) => {
   return (
      <RoundBadgeButton
         onClick={onClick}
         color={color}
         borderColor={borderColor}
         size={size}
         {...props}
      >
         {props.children}
      </RoundBadgeButton>
   );
};

RoundIconButton.propTypes = {
   color: PropTypes.string,
   borderColor: PropTypes.string,
   size: PropTypes.number,
   onClick: PropTypes.func.isRequired,
};

export default RoundIconButton;
