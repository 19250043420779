import * as React from 'react';
import { Component, SVGProps } from 'react';
import { color } from '@dozuki/web-js/primitives';

export class CheckDefault extends Component<SVGProps<SVGSVGElement>> {
   render() {
      return (
         <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            data-testid="check-default"
            {...this.props}
         >
            <path
               d="m12.70711,6.20711c0.18096,-0.18097 0.29289,-0.43097 0.29289,-0.70711c0,-0.55228 -0.44772,-1 -1,-1c-0.27846,0 -0.53034,0.11382 -0.71165,0.29746l-4.28834,4.28834l-2.29287,-2.29287c-0.18097,-0.18099 -0.43098,-0.29293 -0.70714,-0.29293c-0.55228,0 -1,0.44772 -1,1c0,0.27615 0.11194,0.52616 0.29291,0.70712l3,3c0.18096,0.18096 0.43096,0.29288 0.70709,0.29288c0.27614,0 0.52614,-0.11193 0.70711,-0.29289l5,-5z"
               fill={color.white}
            />
         </svg>
      );
   }
}

export class MinusDefault extends Component<SVGProps<SVGSVGElement>> {
   render() {
      return (
         <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            data-testid="minus-default"
            {...this.props}
         >
            <path
               clipRule="evenodd"
               fillRule="evenodd"
               d="m11,7l-6,0c-0.55,0 -1,0.45 -1,1c0,0.55 0.45,1 1,1l6,0c0.55,0 1,-0.45 1,-1c0,-0.55 -0.45,-1 -1,-1z"
               fill={color.white}
            />
         </svg>
      );
   }
}

export class CheckLarge extends Component<SVGProps<SVGSVGElement>> {
   render() {
      return (
         <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            data-testid="check-large"
            {...this.props}
         >
            <path
               clipRule="evenodd"
               fillRule="evenodd"
               d="m8.70711,14.20711c-0.18097,0.18096 -0.43097,0.29289 -0.70711,0.29289c-0.27614,0 -0.52614,-0.11193 -0.70711,-0.29289l-3,-3c-0.18096,-0.18097 -0.29289,-0.43097 -0.29289,-0.70711c0,-0.55228 0.44772,-1 1,-1c0.27614,0 0.52614,0.11193 0.70711,0.29289l2.29289,2.2929l6.2929,-6.2929c0.181,-0.18096 0.431,-0.29289 0.7071,-0.29289c0.5523,0 1,0.44772 1,1c0,0.27614 -0.1119,0.52614 -0.2929,0.70711l-6.99999,7z"
               fill={color.white}
            />
         </svg>
      );
   }
}

export class MinusLarge extends Component<SVGProps<SVGSVGElement>> {
   render() {
      return (
         <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            data-testid="minus-large"
            {...this.props}
         >
            <path
               clipRule="evenodd"
               fillRule="evenodd"
               d="m14,9l-8,0c-0.55,0 -1,0.45 -1,1c0,0.55 0.45,1 1,1l8,0c0.55,0 1,-0.45 1,-1c0,-0.55 -0.45,-1 -1,-1z"
               fill={color.white}
            />
         </svg>
      );
   }
}
