import * as React from 'react';
import styled from 'styled-components';
import { More } from 'core-icons';
import constants from 'Shared/core-components/constants';

const { borderRadius, color, shadows, spacing } = constants;

const MenuContainer = styled.div`
   box-shadow: ${shadows.dropShadow[2]};
   padding: ${spacing[1]} 0;
   border-radius: ${borderRadius[1]};
   display: flex;
   flex-direction: column;
   position: absolute;
   top: ${spacing[5]};
   right: -${spacing[7]};
   background-color: ${color.white};
   z-index: 1;
`;

const MenuOption = styled.span`
   font-size: 0.875rem;
   white-space: nowrap;
   color: ${color.gray[7]};
   padding: ${spacing[0]} ${spacing[3]};
   cursor: pointer;
   :hover {
      background-color: ${color.gray[0]};
   }
`;

const Menu = ({ options }) => (
   <MenuContainer>
      {options.map(({ text, action }, idx) => (
         <MenuOption key={idx} onClick={action} data-testid={`menu-item-${idx}`}>
            {text}
         </MenuOption>
      ))}
   </MenuContainer>
);

const ActionMenuContainer = styled.div`
   position: relative;
   cursor: pointer;
   display: flex;
   align-items: center;
`;

class ActionMenu extends React.Component {
   state = {
      isOpen: false,
   };

   componentWillUnmount() {
      document.removeEventListener('click', this.hideMenu);
   }

   componentDidUpdate() {
      setTimeout(() => {
         if (this.state.isOpen) {
            document.addEventListener('click', this.hideMenu);
         } else {
            document.removeEventListener('click', this.hideMenu);
         }
      }, 0);
   }

   showMenu = event => {
      event.preventDefault();
      event.stopPropagation();
      this.setState({ isOpen: true });
   };

   hideMenu = () => {
      this.setState({ isOpen: false });
   };

   render() {
      const { options, ...props } = this.props;
      const { isOpen } = this.state;

      return (
         <ActionMenuContainer onClick={this.showMenu} {...props}>
            <More fillColor={color.gray[3]} size={16} />
            {isOpen && <Menu options={options} />}
         </ActionMenuContainer>
      );
   }
}

export default ActionMenu;
