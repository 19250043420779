import * as React from 'react';
import ReactDOM from 'react-dom';
import { node } from 'prop-types';

// Portals don't seem to work with stateless functional components
const Header = ({ children }) => {
   const headerElement = document.querySelector('.main-header');

   if (headerElement) {
      // Render the children into the page <header> element
      return ReactDOM.createPortal(children, headerElement);
   } else {
      console.error('Could not locate <header> element');
      return children;
   }
};

Header.propTypes = {
   /**
    * Portals don't seem to work with stateless functional components as
    * children.
    */
   children: node.isRequired,
};

export default Header;
