import borderRadius from './borderRadius';
import color from './color';
import * as Icons from 'core-icons';
import modalBGOverlay from './modalBGOverlay';
import shadows from './shadows';
import spacing from './spacing';
import typography from './typography';

export default {
   borderRadius,
   color,
   // Icons will be moved out of constants with https://github.com/iFixit/dozuki/issues/812
   Icons,
   modalBGOverlay,
   shadows,
   spacing,
   typography,
};
