import * as React from 'react';
import styled from 'styled-components';
import { bool, func, number, string } from 'prop-types';
import { ChevronDown, ChevronUp } from 'core-icons';
import constants from 'Shared/core-components/constants';

const { color } = constants;

const ChevronToggle = ({ open, ...props }) => {
   const ChevronIcon = open ? ChevronUp : ChevronDown;
   const StyledIcon = styled(ChevronIcon)`
      cursor: pointer;
   `;

   const fillColor = open ? color.gray[4] : color.gray[3];

   return <StyledIcon fillColor={fillColor} {...props} />;
};

ChevronToggle.propTypes = {
   className: string,
   open: bool,
   size: number,
};

ChevronToggle.defaultProps = {
   className: '',
   open: false,
   size: 16,
};

export default ChevronToggle;
