import { color } from '@dozuki/web-js/primitives';
import { ButtonType, ButtonColor } from './button';

type StylesByButtonType = {
   [key in ButtonType]: ByButtonColor;
};

type ByButtonColor = {
   [key in ButtonColor]?: ButtonInteractions;
};

interface ButtonInteractions {
   default: ButtonColorStyles;
   hover: ButtonColorStyles;
   focus: ButtonColorStyles;
}

interface ButtonColorStyles {
   fontColor: string;
   backgroundColor: string;
   border: string;
}

const darkBlue: ButtonInteractions = {
   default: {
      fontColor: color.white,
      backgroundColor: color.blue[500],
      border: 'none',
   },
   hover: {
      fontColor: color.white,
      backgroundColor: color.blue[600],
      border: 'none',
   },
   focus: {
      fontColor: color.white,
      backgroundColor: color.blue[700],
      border: 'none',
   },
};

const lightBlack: ButtonInteractions = {
   default: {
      fontColor: color.gray[800],
      backgroundColor: color.gray[50],
      border: 'none',
   },
   hover: {
      fontColor: color.gray[800],
      backgroundColor: color.gray[200],
      border: 'none',
   },
   focus: {
      fontColor: color.gray[800],
      backgroundColor: color.gray[300],
      border: 'none',
   },
};

export const stylesByButtonType: StylesByButtonType = {
   default: {
      default: {
         default: {
            fontColor: color.gray[800],
            backgroundColor: color.white,
            border: `1px solid ${color.gray[300]}`,
         },
         hover: {
            fontColor: color.gray[800],
            backgroundColor: color.white,
            border: `1px solid ${color.gray[500]}`,
         },
         focus: {
            fontColor: color.gray[800],
            backgroundColor: color.white,
            border: `1px solid ${color.gray[700]}`,
         },
      },
   },

   primary: {
      default: darkBlue,
   },

   secondary: {
      default: lightBlack,
   },

   dark: {
      default: darkBlue,
      green: {
         default: {
            fontColor: color.white,
            backgroundColor: color.green[500],
            border: 'none',
         },
         hover: {
            fontColor: color.white,
            backgroundColor: color.green[600],
            border: 'none',
         },
         focus: {
            fontColor: color.white,
            backgroundColor: color.green[700],
            border: 'none',
         },
      },
      red: {
         default: {
            fontColor: color.white,
            backgroundColor: color.red[500],
            border: 'none',
         },
         hover: {
            fontColor: color.white,
            backgroundColor: color.red[600],
            border: 'none',
         },
         focus: {
            fontColor: color.white,
            backgroundColor: color.red[700],
            border: 'none',
         },
      },
      yellow: {
         default: {
            fontColor: color.gray[800],
            backgroundColor: color.yellow[500],
            border: 'none',
         },
         hover: {
            fontColor: color.gray[800],
            backgroundColor: color.yellow[600],
            border: 'none',
         },
         focus: {
            fontColor: color.gray[800],
            backgroundColor: color.yellow[700],
            border: 'none',
         },
      },
      blue: darkBlue,
      black: {
         default: {
            fontColor: color.white,
            backgroundColor: color.gray[900],
            border: 'none',
         },
         hover: {
            fontColor: color.white,
            backgroundColor: color.gray[800],
            border: 'none',
         },
         focus: {
            fontColor: color.white,
            backgroundColor: color.gray[700],
            border: 'none',
         },
      },
   },

   light: {
      default: lightBlack,
      green: {
         default: {
            fontColor: color.green[600],
            backgroundColor: color.green[100],
            border: 'none',
         },
         hover: {
            fontColor: color.green[700],
            backgroundColor: color.green[200],
            border: 'none',
         },
         focus: {
            fontColor: color.green[800],
            backgroundColor: color.green[300],
            border: 'none',
         },
      },
      red: {
         default: {
            fontColor: color.red[600],
            backgroundColor: color.red[100],
            border: 'none',
         },
         hover: {
            fontColor: color.red[700],
            backgroundColor: color.red[200],
            border: 'none',
         },
         focus: {
            fontColor: color.red[800],
            backgroundColor: color.red[300],
            border: 'none',
         },
      },
      yellow: {
         default: {
            fontColor: color.yellow[600],
            backgroundColor: color.yellow[100],
            border: 'none',
         },
         hover: {
            fontColor: color.yellow[700],
            backgroundColor: color.yellow[200],
            border: 'none',
         },
         focus: {
            fontColor: color.yellow[800],
            backgroundColor: color.yellow[300],
            border: 'none',
         },
      },
      blue: {
         default: {
            fontColor: color.blue[600],
            backgroundColor: color.blue[50],
            border: 'none',
         },
         hover: {
            fontColor: color.blue[700],
            backgroundColor: color.blue[200],
            border: 'none',
         },
         focus: {
            fontColor: color.blue[800],
            backgroundColor: color.blue[300],
            border: 'none',
         },
      },
      black: lightBlack,
   },

   'dark-legacy': {
      default: {
         default: {
            fontColor: color.gray[50],
            backgroundColor: '#343739',
            border: 'none',
         },
         hover: {
            fontColor: color.gray[50],
            backgroundColor: color.gray[700],
            border: 'none',
         },
         focus: {
            fontColor: color.gray[50],
            backgroundColor: color.gray[700],
            border: 'none',
         },
      },
   },
};
