import * as React from 'react';
import styled from 'styled-components';
import ReactCheckboxTree from 'react-checkbox-tree';
import { CaretDown, CaretRight } from 'core-icons';

const TreeContainer = styled.span`
   .rct-collapse.rct-collapse-btn {
      display: flex;
   }
`;

const CheckboxTree = props => {
   return (
      <TreeContainer>
         <ReactCheckboxTree
            {...props}
            nativeCheckboxes
            icons={{
               expandClose: <CaretRight />,
               expandOpen: <CaretDown />,
            }}
         />
      </TreeContainer>
   );
};

export default CheckboxTree;
