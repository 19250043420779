import * as React from 'react';
import { string, node } from 'prop-types';
import styled from 'styled-components';
import constants from 'Shared/core-components/constants';

const Badge = styled.div`
   width: ${props => `${props.size}`};
   height: ${props => `${props.size}`};
   display: flex;
   justify-content: center;
   align-items: center;
   border: ${props => props.borderWidth} solid;
   border-color: ${props => props.borderColor};
   border-radius: 100%;
   background-color: ${props => (props.fillColor ? props.fillColor : 'transparent')};
`;

const RoundBadge = ({
   color = constants.color.gray[1],
   fillColor = null,
   borderColor = constants.color.gray[1],
   borderWidth = '0.125rem',
   size = '2rem',
   ...props
}) => {
   return (
      <Badge
         color={color}
         fillColor={fillColor}
         borderColor={borderColor}
         borderWidth={borderWidth}
         size={size}
         {...props}
      >
         {props.children}
      </Badge>
   );
};

RoundBadge.propTypes = {
   color: string,
   fillColor: string,
   borderColor: string,
   borderWidth: string,
   size: string,
   children: node,
};

export default RoundBadge;
