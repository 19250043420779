import {
   AlertDialog,
   AlertDialogBody,
   AlertDialogContent,
   AlertDialogFooter,
   AlertDialogHeader,
   AlertDialogOverlay,
   Button,
   MenuItem,
   Stack,
} from '@chakra-ui/react';
import * as React from 'react';
import { initializeChakraComponent } from 'Shared/chakra-initialize';
import { _js } from '@ifixit/localize';

interface AlertModalConfirmationProps {
   triggerText: any;
   triggerColor: any;
   modalTitle: string;
   modalMessage: string;
   modalButtonColor?: string;
   onConfirm: () => void;
}

export const AlertModalConfirmation = (props: AlertModalConfirmationProps) => {
   const [isOpen, setIsOpen] = React.useState(false);

   const openAlert = () => {
      setIsOpen(true);
   };

   const closeAlert = () => {
      setIsOpen(false);
   };

   const handleConfirm = () => {
      closeAlert();
      props.onConfirm();
   };

   return (
      <React.Fragment>
         <MenuItem onClick={openAlert} color={props.triggerColor}>
            {props.triggerText}
         </MenuItem>
         <AlertDialog isOpen={isOpen} leastDestructiveRef={undefined} onClose={closeAlert}>
            <AlertDialogOverlay>
               <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                     {props.modalTitle}
                  </AlertDialogHeader>
                  <AlertDialogBody>{props.modalMessage}</AlertDialogBody>
                  <AlertDialogFooter>
                     <Stack spacing={4} direction="row" align="center" justify="flex-end">
                        <Button colorScheme="gray" variant="solid" onClick={closeAlert}>
                           {_js('Cancel')}
                        </Button>
                        <Button
                           colorScheme={props.modalButtonColor ? props.modalButtonColor : 'blue'}
                           variant="solid"
                           onClick={handleConfirm}
                        >
                           {_js('Confirm')}
                        </Button>
                     </Stack>
                  </AlertDialogFooter>
               </AlertDialogContent>
            </AlertDialogOverlay>
         </AlertDialog>
      </React.Fragment>
   );
};

initializeChakraComponent('AlertModalConfirmation', AlertModalConfirmation);
