import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space, fontSize, borderRadius } from '@dozuki/web-js/primitives';
import { CaretDown, CaretUp } from 'core-icons';
import color from 'Shared/core-components/constants/color';

const DropdownContainer = styled.div`
   position: relative;
   height: 2.25rem;
`;

const DropdownButton = styled.button`
   cursor: pointer;
   height: 100%;
   width: inherit;
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: ${space[2]} ${space[4]};
   border: 1px solid;
   border-color: ${color.gray[2]};
   border-radius: ${borderRadius.md};
   background-color: ${color.white};
   color: ${color.gray[7]};
   font-size: ${fontSize.md};

   &:focus {
      outline: none;
      border-color: ${color.gray[3]};
   }
`;

const DropdownList = styled.ul`
   max-height: ${props => props.dropdownListMaxHeight};
   overflow-y: auto;
   width: 100%;
   display: ${props => (props.isOpen ? 'block' : 'none')};
   position: absolute;
   z-index: 10;
   padding: ${space[1]} 0;
   margin: 2px 0 0;
   list-style: none;
   background-color: ${color.white};
   border-radius: ${borderRadius.md};
   /* TODO: replace box-shadow with core-components Shadow-3 */
   box-shadow: 0 6px 18px rgba(16, 22, 26, 0.2), 0 2px 4px rgba(16, 22, 26, 0.2);
   background-clip: padding-box;
   font-size: ${fontSize.md};
`;

const DropdownListItem = styled.li`
   display: flex;
   justify-content: space-between;
   align-items: center;
   cursor: pointer;
   color: ${color.gray[7]};
   padding: ${space[2]} ${space[4]};
   background: none;
   margin-left: 0;

   :hover {
      background-color: ${color.gray[0]};
   }
`;

const DropdownInput = ({ title, isOpen, onClick }) => {
   const Caret = isOpen ? CaretUp : CaretDown;
   const StyledCaret = styled(Caret)`
      margin-left: ${space[1]};
   `;

   return (
      <DropdownButton type="button" className="dropdown-button" isOpen={isOpen} onClick={onClick}>
         {title} <StyledCaret />
      </DropdownButton>
   );
};

const DropdownItemSecondaryText = styled.span`
   color: ${color.gray[5]};
   font-size: ${fontSize.sm};
`;

const dropdownItemPropTypes = {
   option: PropTypes.object.isRequired,
   onSelect: PropTypes.func.isRequired,
};

/**
 * A selectable dropdown menu item which can display both primary and secondary
 * text.
 *
 * @param {Object} option An object of the format:
 * {value: any, primaryLabel: String, secondaryLabel: String}, where
 * secondaryLabel is optional.
 * @param {Function} onSelect Callback that is called when a dropdown item is
 * selected. `option.value` will be passed as the first parameter to onSelect.
 */
const DropdownItem = ({ option, onSelect }) => {
   const itemSecondaryText = option.secondaryLabel ? (
      <DropdownItemSecondaryText>{option.secondaryLabel}</DropdownItemSecondaryText>
   ) : null;

   return (
      <DropdownListItem data-value={option.value} onClick={() => onSelect(option)}>
         {option.primaryLabel} {itemSecondaryText}
      </DropdownListItem>
   );
};

DropdownItem.propTypes = dropdownItemPropTypes;

const dropdownPropTypes = {
   /**
    * Title to be displayed in the dropdown menu button text.
    */
   title: PropTypes.string.isRequired,

   /**
    * An object of the format:
    * {value: any, primaryLabel: String, secondaryLabel: String}, where
    * primaryLabel is the primary dropdown item label, and secondaryLabel is a
    * second label displayed in muted text to the right of the primary label.
    */
   options: PropTypes.arrayOf(PropTypes.object).isRequired,

   /**
    * Function of the format: function(value) {...}. option.value will be passed
    * as the first parameter to this function.
    */
   onSelect: PropTypes.func.isRequired,

   /**
    * The maximum height of the dropdown menu list in pixels before the user
    * will need to scroll down to view further dropdown items.
    */
   dropdownListMaxHeight: PropTypes.string,
};

const dropdownDefaultProps = {
   dropdownListMaxHeight: '250px',
};

export default class Dropdown extends React.Component {
   state = {
      isOpen: false,
   };

   showDropdownMenu = event => {
      event.preventDefault();
      this.setState({ isOpen: true });
   };

   hideDropdownMenu = () => {
      this.setState({ isOpen: false });
   };

   componentDidUpdate() {
      setTimeout(() => {
         if (this.state.isOpen) {
            document.addEventListener('click', this.hideDropdownMenu);
         } else {
            document.removeEventListener('click', this.hideDropdownMenu);
         }
      }, 0);
   }

   onSelect = option => {
      this.props.onSelect(option.value);
      this.setState({
         isOpen: false,
      });
   };

   componentWillUnmount() {
      document.removeEventListener('click', this.hideDropdownMenu);
   }

   render() {
      const { title, options, dropdownListMaxHeight, className = '' } = this.props;
      const { isOpen } = this.state;

      return (
         <DropdownContainer
            className={className}
            data-testid={`search-dropdown-${title.toLowerCase()}`}
         >
            <DropdownInput title={title} isOpen={isOpen} onClick={this.showDropdownMenu} />
            <DropdownList
               className="dropdown-list"
               isOpen={isOpen}
               dropdownListMaxHeight={dropdownListMaxHeight}
            >
               {options.map((option, index) => (
                  <DropdownItem key={index} option={option} onSelect={this.onSelect} />
               ))}
            </DropdownList>
         </DropdownContainer>
      );
   }
}

Dropdown.propTypes = dropdownPropTypes;
Dropdown.defaultProps = dropdownDefaultProps;
