import * as React from 'react';
import styled from 'styled-components';
import constants from 'Shared/core-components/constants';

const { color } = constants;

const ProgressBarContainer = styled.div`
   width: 100%;
   height: ${props => props.height};
   background-color: ${props => props.backgroundColor};
   border-radius: ${props => props.borderRadius};
`;

const Progress = styled.div`
   width: ${props => props.progress}%;
   height: 100%;
   background-color: ${props => props.progressBarColor};
   border-radius: inherit;
`;

const ProgressBar = ({
   progress,
   progressBarColor = color.blue,
   backgroundColor = color.gray[1],
   borderRadius = constants.borderRadius[1],
   height = '0.5rem',
   ...props
}) => {
   return (
      <ProgressBarContainer
         backgroundColor={backgroundColor}
         borderRadius={borderRadius}
         height={height}
         {...props}
      >
         <Progress progress={progress} progressBarColor={progressBarColor} />
      </ProgressBarContainer>
   );
};

export default ProgressBar;
