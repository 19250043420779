import * as React from 'react';
import { string, number, func, bool } from 'prop-types';
import styled from 'styled-components';
import Autosuggest from 'react-autosuggest';
import debounce from 'lodash/debounce';
import { fontSize, lineHeight, transition } from '@dozuki/web-js/primitives';
import coreComponentsConstants from 'Shared/core-components/constants';

const { color, spacing, borderRadius, shadows } = coreComponentsConstants;

const AutoSuggestContainer = styled.div`
   width: 100%;
`;

const SearchInput = styled.input.attrs({
   'data-testid': 'course-name-textbox',
})`
   display: block;
   -webkit-appearance: none;
   height: 2.25rem;
   width: 100%;
   box-sizing: border-box;
   padding: ${spacing[0]} ${spacing[3]};
   margin: 0;
   font-family: inherit;
   font-size: ${fontSize.lg};
   line-height: ${lineHeight.normal};
   border: 1px solid ${color.gray[2]};
   border-radius: ${borderRadius[1]};
   outline: none;
   color: ${color.gray[7]};
   background-color: ${color.white};
   transition: padding ${transition[150]};
   box-shadow: none;
`;

const SuggestionListContainer = styled.div`
   position: relative;
`;

const SuggestionsList = styled.div`
   position: absolute;
   z-index: 50;
   margin: ${spacing[0]} 0 0 0;
   padding: 0;
   list-style: none;
   background-color: ${color.white};
   box-shadow: ${shadows.dropShadow[2]};
   border-radius: ${borderRadius[1]}
   overflow-x: hidden;

   & > ul {
      list-style: none;
      padding-left: 0;
   }
`;

const Suggestion = styled.div`
   display: flex;
   align-items: center;
   cursor: pointer;
   overflow: hidden;
   padding: ${spacing[0]} ${spacing[3]};
   color: ${color.gray[7]};
`;

const SelectedSuggestion = styled(Suggestion)`
   /* Darker color for keyboard navigation */
   background-color: ${color.gray[1]};

   :hover {
      background-color: ${color.gray[0]};
   }
`;

const SuggestionTitle = styled.p`
   margin: 0;
   font-size: 0.875rem;
   line-height: 1.0625rem;
   color: ${color.black};
`;

export default class AutoSuggest extends React.Component {
   static propTypes = {
      /** An initial string to fill the search input with. */
      searchInputInitialValue: string,

      /** The search input's placeholder value. */
      searchInputPlaceholder: string.isRequired,

      /**
       * Callback to create the suggestion API request. Takes a single
       * parameter with the search query string and must return a
       * Request.API_2_0.
       */
      createSuggestionRequest: func.isRequired,

      /**
       * Callback to send the suggestion API request. The return value of
       * createSuggestionRequest is sent as the first parameter to this
       * function.
       */
      sendSuggestionRequest: func.isRequired,

      /**
       * Callback to handle the suggestion API response. Must return an array of
       * objects of the shape { displayTitle: string, data: object }.
       */
      processSuggestionsResponse: func.isRequired,

      /** Search debounce timeout in milliseconds. */
      debounceTimeout: number,

      /**
       * Callback that is called when a user selects a specific suggestion. The
       * return value of processSuggestionsResponse is passed as the one
       * argument to this function.
       */
      onSelectSuggestion: func.isRequired,
   };

   static defaultProps = {
      debounceTimeout: 200,
   };

   state = {
      value: '',
      suggestions: [],
      pendingRequest: null,
      areSuggestionsShown: false,
      hasSearchInputChanged: false,
   };

   onChange = (event, { newValue }) => {
      // Allow users to navigate suggestions with the up/down arrow keys
      if (this.state.areSuggestionsShown && ['ArrowDown', 'ArrowUp'].contains(event.key)) {
         event.preventDefault();
      } else if (newValue !== undefined) {
         this.setState({
            value: newValue,
            hasSearchInputChanged: true,
         });
      }
   };

   onSuggestionsFetchRequested = ({ value, reason }) => {
      if (reason === 'input-changed') {
         const { pendingRequest } = this.state;

         if (pendingRequest !== null) {
            pendingRequest.request.cancel();
            this.setState({ pendingRequest: null });
         }

         this.debouncedSearchGuideSuggestions(response => {
            this.setState({
               suggestions: this.props.processSuggestionsResponse(response),
               pendingRequest: null,
               areSuggestionsShown: true,
            });
         });
      }
   };

   debouncedSearchGuideSuggestions = debounce(callback => {
      const { createSuggestionRequest, sendSuggestionRequest } = this.props;
      const encodedQuery = encodeURIComponent(this.state.value);
      let request = createSuggestionRequest(encodedQuery);

      this.setState({
         pendingRequest: request,
      });

      sendSuggestionRequest(request).then(callback);
   }, this.props.debounceTimeout);

   onSuggestionsClearRequested = () => {
      this.setState({ suggestions: [], areSuggestionsShown: false });
   };

   getSuggestionValue = guideSuggestion => {
      return guideSuggestion.title;
   };

   renderInputComponent = inputProps => {
      return <SearchInput {...inputProps} autoFocus />;
   };

   renderSuggestionsContainer = ({ containerProps, children, query }) => {
      return (
         <SuggestionListContainer {...containerProps}>
            <SuggestionsList data-testid="course-suggestion-list">{children}</SuggestionsList>
         </SuggestionListContainer>
      );
   };

   renderSuggestion = (suggestion, { query, isHighlighted }) => {
      let SuggestionContainer = isHighlighted ? SelectedSuggestion : Suggestion;

      return (
         <SuggestionContainer
            data-testid={`suggestion-item-${this.state.suggestions.indexOf(suggestion)}`}
         >
            <SuggestionTitle>{suggestion.displayTitle}</SuggestionTitle>
         </SuggestionContainer>
      );
   };

   onSuggestionSelected = (event, { suggestion }) => {
      this.props.onSelectSuggestion(suggestion.data);
   };

   render() {
      const { value, suggestions, hasSearchInputChanged } = this.state;
      const { searchInputInitialValue, searchInputPlaceholder, ...props } = this.props;

      const inputProps = {
         placeholder: searchInputPlaceholder,
         value:
            !hasSearchInputChanged && searchInputInitialValue
               ? searchInputInitialValue
               : value || '',
         onChange: this.onChange,
      };

      return (
         <AutoSuggestContainer {...props}>
            <Autosuggest
               suggestions={suggestions}
               focusInputOnSuggestionClick={false}
               onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
               onSuggestionsClearRequested={this.onSuggestionsClearRequested}
               getSuggestionValue={this.getSuggestionValue}
               renderInputComponent={this.renderInputComponent}
               renderSuggestion={this.renderSuggestion}
               renderSuggestionsContainer={this.renderSuggestionsContainer}
               onSuggestionSelected={this.onSuggestionSelected}
               inputProps={inputProps}
            />
         </AutoSuggestContainer>
      );
   }
}
