import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLoader } from '@fortawesome/pro-regular-svg-icons';

const rotate360 = keyframes`
   from {
      transform: rotate(0deg);
   }

   to {
      transform: rotate(360deg);
   }
`;

const LoadingContainer = styled.div`
   width: ${props => props.size}px;
   height: ${props => props.size}px;
   text-align: center;
   animation: ${rotate360} 2s infinite linear;
`;

const LoadingIndicator = ({ size, ...props }) => (
   <LoadingContainer size={size} {...props}>
      <FontAwesomeIcon icon={faLoader} size={size} className="svg-icon" />
   </LoadingContainer>
);

export default LoadingIndicator;
