import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { breakpoint } from '@dozuki/web-js/primitives';
import coreComponentsConstants from 'Shared/core-components/constants';

const { color, spacing, borderRadius, shadows } = coreComponentsConstants;

const CardContent = styled.div`
   display: flex;
   flex-flow: row nowrap;
   align-items: center;
   flex: 1;
   width: 100%;
   height: 7.5rem;
   padding: 0 ${spacing[3]};
   box-shadow: ${shadows.dropShadow[0]};
   background-color: ${color.white};
   border-radius: ${borderRadius[1]};

   @media (min-width: ${breakpoint.sm}) {
      padding-left: 0;
      padding-right: ${spacing[5]};
   }
`;

const CardBodyContainer = styled.div`
   display: flex;
   flex-flow: column wrap;
   flex: 1;
`;

const CardControlsContainer = styled.div`
   display: flex;
   justify-content: flex-end;
   align-items: center;
   color: ${color.gray[4]};
`;

const Card = ({ children, ...props }) => {
   return <CardContent {...props}>{children}</CardContent>;
};

Card.propTypes = {
   children: PropTypes.node.isRequired,
};

export default { Card, CardBodyContainer, CardControlsContainer };
